export default {
  'common': {
    'heart': '❤️',
    'tada': '🎉',
    'thumbsup': '👍',
    'check_mark': '✔️',
    'plus': '➕',
    'minus': '➖',
    '100': '💯',
    'rocket': '🚀',
    'shrug': '🤷',
    'eyes': '👀',
    'dart': '🎯'
  },
  'hands': {
    'pray': '🙏',
    'fingers_crossed': '🤞',
    'clap': '👏',
    'fist': '✊',
    'raised_hands': '🙌',
    'v': '✌️',
    'metal': '🤘',
    'wave': '👋',
    'call_me': '🤙',
    'thumbs_up': '👍',
    'thumbsdown': '👎',
    'point_up': '☝️',
    'ok_hand': '👌',
    'writing_hand': '✍️',
    'punch': '👊',
    'hand': '✋',
    'open_hands': '👐',
    // 'point_up_2': '👆',
    // 'point_down': '👇',
    // 'point_right': '👉',
    // 'point_left': '👈',
    'muscle': '💪'
  },
  'expressions': {
    'simple_smile': '🙂',
    'joy': '😂',
    'sweat_smile': '😅',
    'upside_down_face': '🙃',
    'heart_eyes': '😍',
    'thinking': '🤔',
    'grinning': '😀',
    'laughing': '😆',
    'hugging': '🤗',
    'grimacing': '😬',
    'expressionless': '😑',
    'blush': '😊',
    'wink': '😉',
    'nerd': '🤓',
    'sunglasses': '😎',
    'relieved': '😌',
    'worried': '😟',
    'confounded': '😖',
    'neutral_face': '😐',
    'slight_frown': '☹️',
    'disappointed': '😞',
    'angry': '😡',
    'weary': '😩',
    'astonished': '😲',
    'rolling_eyes': '🙄',
    'cry': '😢',
    'scream': '😱',
    'neutral_face': '😐',
    'slight_frown': '☹️',
    'scream': '😱',
    'sleeping': '😴',
    'smile': '😄',
    'kissing_heart': '😘',
    'kissing_closed_eyes': '😚',
    'stuck_out_tongue_closed_eyes': '😝',
    'stuck_out_tongue': '😛',
    'flushed': '😳',
    'grin': '😁',
    'unamused': '😒',
    'disappointed': '😞',
    'sob': '😭',
    'sleepy': '😪',
    'disappointed_relieved': '😥',
    'cold_sweat': '😰',
    'sweat': '😓',
    'fearful': '😨',
    'scream': '😱',
    'angry': '😠',
    'rage': '😡',
    'triumph': '😤',
    'confounded': '😖',
    'yum': '😋',
    'mask': '😷',
    'dizzy_face': '😵',
    'frowning': '😦',
    'imp': '👿',
    'open_mouth': '😮',
    'smirk': '😏'
  },
  'people': {
    'people_hugging': '🫂',
    'eyes': '👀',
    'brain': '🧠',
    'shrug': '🤷',
    'facepalm': '🤦',
    'ok_woman': '🙆',
    'no_good': '🙅',
    'information_desk_person': '💁',
    'raising_hand': '🙋',
    'massage': '💆',
    'haircut': '💇',
    'hankey': '💩',
    'see_no_evil': '🙈',
    'hear_no_evil': '🙉',
    'speak_no_evil': '🙊',
    'alien': '👽',
    'cop': '👮',
    'construction_worker': '👷',
    'older_man': '👴',
    'older_woman': '👵',
    'angel': '👼',
    'princess': '👸',
    'smiley_cat': '😺',
    'smile_cat': '😸',
    'heart_eyes_cat': '😻',
    'kissing_cat': '😽',
    'smirk_cat': '😼',
    'scream_cat': '🙀',
    'crying_cat_face': '😿',
    'joy_cat': '😹',
    'pouting_cat': '😾',
    'skull': '💀',
    'ear': '👂',
    'nose': '👃',
    'tongue': '👅',
    'runner': '🏃',
    'dancer': '💃',
    'couple': '👫',
    'family': '👪',
    'couplekiss': '💏',
    'couple_with_heart': '💑',
    'dancers': '👯',
    'nail_care': '💅',
    'bride_with_veil': '👰',
    'person_with_pouting_face': '🙎',
    'person_frowning': '🙍',
    'bow': '🙇',
  },
  'nature': {
    'rainbow': '🌈',
    'goat': '🐐',
    'squid': '🦑',
    'dog': '🐶',
    'wolf': '🐺',
    'cat': '🐱',
    'mouse': '🐭',
    'hamster': '🐹',
    'rabbit': '🐰',
    'frog': '🐸',
    'tiger': '🐯',
    'koala': '🐨',
    'bear': '🐻',
    'pig': '🐷',
    'cow': '🐮',
    'boar': '🐗',
    'monkey_face': '🐵',
    'monkey': '🐒',
    'horse': '🐴',
    'sheep': '🐑',
    'elephant': '🐘',
    'panda_face': '🐼',
    'penguin': '🐧',
    'bird': '🐦',
    'baby_chick': '🐤',
    'hatched_chick': '🐥',
    'hatching_chick': '🐣',
    'chicken': '🐔',
    'snake': '🐍',
    'turtle': '🐢',
    'bug': '🐛',
    'bee': '🐝',
    'ant': '🐜',
    'beetle': '🐞',
    'snail': '🐌',
    'octopus': '🐙',
    'shell': '🐚',
    'tropical_fish': '🐠',
    'fish': '🐟',
    'dolphin': '🐬',
    'whale': '🐳',
    'racehorse': '🐎',
    'dragon_face': '🐲',
    'blowfish': '🐡',
    'camel': '🐫',
    'poodle': '🐩',
    'feet': '🐾',
    'bouquet': '💐',
    'cherry_blossom': '🌸',
    'tulip': '🌷',
    'four_leaf_clover': '🍀',
    'rose': '🌹',
    'sunflower': '🌻',
    'hibiscus': '🌺',
    'maple_leaf': '🍁',
    'leaves': '🍃',
    'fallen_leaf': '🍂',
    'herb': '🌿',
    'mushroom': '🍄',
    'cactus': '🌵',
    'palm_tree': '🌴',
    'seedling': '🌱',
    'new_moon': '🌑',
    'first_quarter_moon': '🌓',
    'moon': '🌔',
    'full_moon': '🌕',
    'crescent_moon': '🌙',
    'partly_sunny': '⛅',
    'snowman': '⛄'
  },
  'objects': {
    '100': '💯',
    'no_entry_sign': '🚫',
    'white_check_mark': '✅',
    'check_mark': '✔️',
    'plus': '➕',
    'minus': '➖',
    'drum': '🥁',
    'popcorn': '🍿',
    'crown': '👑',
    'briefcase': '💼',
    'fire': '🔥',
    'sparkles': '✨',
    'star': '⭐',
    'star2': '🌟',
    'sweat_drops': '💦',
    'droplet': '💧',
    'zzz': '💤',
    'dash': '💨',
    'broken_heart': '💔',
    'footprints': '👣',
    'mortar_board': '🎓',
    'jack_o_lantern': '🎃',
    'ghost': '👻',
    'santa': '🎅',
    'christmas_tree': '🎄',
    'gift': '🎁',
    'confetti_ball': '🎊',
    'balloon': '🎈',
    'crystal_ball': '🔮',
    'movie_camera': '🎥',
    'camera': '📷',
    'floppy_disk': '💾',
    'computer': '💻',
    'bell': '🔔',
    'loudspeaker': '📢',
    'hourglass': '⌛',
    'alarm_clock': '⏰',
    'unlock': '🔓',
    'lock': '🔒',
    'key': '🔑',
    'bulb': '💡',
    'battery': '🔋',
    'mag': '🔍',
    'toilet': '🚽',
    'wrench': '🔧',
    'bomb': '💣',
    'hocho': '🔪',
    'pill': '💊',
    'moneybag': '💰',
    'money_with_wings': '💸',
    'memo': '📝',
    'page_facing_up': '📄',
    'chart_with_upwards_trend': '📈',
    'calendar': '📆',
    'microphone': '🎤',
    'headphones': '🎧',
    'musical_note': '🎵',
    'violin': '🎻',
    'trumpet': '🎺',
    'saxophone': '🎷',
    'guitar': '🎸',
    'space_invader': '👾',
    'video_game': '🎮',
    'mahjong': '🀄',
    'game_die': '🎲',
    'dart': '🎯',
    'checkered_flag': '🏁',
    'trophy': '🏆',
    'beers': '🍻',
    'arrow_right': '➡️',
    'arrow_left': '⬅️',
    'arrow_up': '⬆️',
    'arrow_down': '⬇️'
  },
  'places': {
    'house': '🏠',
    'roller_coaster': '🎢',
    'ship': '🚢',
    'boat': '⛵',
    'speedboat': '🚤',
    'rocket': '🚀',
    'seat': '💺',
    'bullettrain_side': '🚄',
    'metro': '🚇',
    'railway_car': '🚃',
    'bus': '🚌',
    'blue_car': '🚙',
    'car': '🚗',
    'taxi': '🚕',
    'truck': '🚚',
    'rotating_light': '🚨',
    'police_car': '🚓',
    'fire_engine': '🚒',
    'ambulance': '🚑',
    'bike': '🚲',
    'construction': '🚧',
    'circus_tent': '🎪',
    'round_pushpin': '📍',
    'triangular_flag_on_post': '🚩',
    'no_smoking': '🚭'
  }
}
