let loomioUrl
let apiBase
let isTwiki
let fileName
let discussionId = 0;
let discussionKey = "";
let discussion;
let commentIds = [];
let stanceIds = [];
let pollOptions = [];
let unknownUserIds = [];
let allComments = [];
let users = [];
let pageSize = 100;
let markdown = "";


function setupVars(isTwikiFormat){
    loomioUrl = new URL(document.URL);
    apiBase = loomioUrl.origin + '/api/v1/'
    isTwiki = isTwikiFormat;
    fileName = loomioUrl.pathname.match(new RegExp("([^\/]+$)"))
     // Reset all global variables on setup to prevent data from being mixed up between exports
    markdown = "";
    discussionId = 0;
    discussionKey = "";
    discussion = null;
    commentIds = [];
    stanceIds = [];
    pollOptions = [];
    unknownUserIds = [];
    allComments = [];
    users = [];
}

let PollTypes = {
    Poll: "poll",
    RankedChoice: "ranked_choice",
    SenseCheck: "check", //handle like poll
    Proposal: "proposal",//handle like poll
    Time: "meeting", //may need to be similar to ranked choice
    OptIn: "count", //probably like poll
    Score: "score", //similar to ranked choice
    DotVote: "dot_vote" //similar to ranked choice
}

//#region constructors
class Discussion {
    constructor(id, author, title, description, general_comments, polls) {
        this.id = id || 0;
        this.author = author || new User(0, "N/A");
        this.title = title;
        this.description = description;
        this.general_comments = general_comments;
        this.polls = polls;
    }
}

class Comment {
    constructor(id, content, author, reactions, parent_id, parent_type, ancestor_type, ancestor_id){
        this.id = id;
        this.content = content;
        this.author = author;
        this.reactions = reactions;
        this.parent_id = parent_id;
        this.parent_type = parent_type;
        this.ancestor_type = ancestor_type;
        this.ancestor_id = ancestor_id;
    }
}

class User {
    constructor(id, name){
        this.id = id;
        this.name = name;
    }
}

class Reaction {
    constructor(id, content, author) {
        this.id = id;
        this.content = content;
        this.author = author;
    }
}

class Poll {
    constructor(id, title, details, results, stances, decided_voters_count, maximum_stance_choices, poll_type){
        this.id = id;
        this.title = title;
        this.details = details;
        this.results = results;
        this.stances = stances;
        this.decided_voters_count = decided_voters_count;
        this.maximum_stance_choices = maximum_stance_choices;
        this.poll_type = poll_type;
    }
}

class Result {
    constructor(id, name, poll_id, score, score_percent, voter_count, voters, voter_percent, sort_priority, rank, voter_scores){
        this.id = id;
        this.name = name;
        this.poll_id = poll_id;
        this.score = score;
        this.score_percent = score_percent;
        this.voter_count = voter_count;
        this.voters = voters;
        this.voter_percent = voter_percent;
        this.sort_priority = sort_priority;
        this.rank = rank;
        this.voter_scores = voter_scores;
    }
}

class Stance {
    constructor(id, pollId, reason, participant, reactions, comments){
        this.id = id;
        this.pollId = pollId;
        this.reason = reason;
        this.participant = participant;
        this.reactions = reactions;
        this.comments = comments;
    }
}
//#endregion


//#region utils
function joinParams(items){
    return items.join("x");
}

function checkUserIdAndAddToArray(id){
    //we need this so we can fetch against /profile?xids=12x34x123 etc. and get all users
    if(!unknownUserIds.includes(id)){
        unknownUserIds.push(id);
    }
}

function isRankedType(pollType){
    //we present the results of a ranked-type poll a little differently
    //TODO: if we find we need to handle each type individually in terms of parsing data/markdown, we might want to add some helper function to do that
    if(pollType == PollTypes.DotVote
        || pollType == PollTypes.RankedChoice
        || pollType == PollTypes.Time
        || pollType == PollTypes.Score
    ){
        return true;
    }
    return false;
}

function strip(html){
    if(!html){
        return "";
    }
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

function flattenAndCountReactions(reactions){
    //loop through reactions and stuff into a string - e.g., 5 ❤, 2 👍️
    let reactionString = "";
    let flattened = reactions.reduce(function(acc, curr) {
        let elemIndex = acc.findIndex(function(item){
            return item.content === curr.content;
        });

        if (elemIndex === -1){
            let obj = {};
            obj.content = curr.content;
            obj.count = 1;
            acc.push(obj);
        }else {
            acc[elemIndex].count += 1;
        }
        return acc;
    }, []);

    let countedReactions = flattened.sort((x, y) => y.count - x.count);

    countedReactions.forEach((cr) => {
        let holdingPlace = [];
        reactionString += cr.count + " " + cr.content + " (";

        let thisSet = reactions.filter(x => x.content == cr.content)

        thisSet.forEach((ts) => {
          holdingPlace.push(ts.author.name)
        })
        reactionString += holdingPlace.join(", ")
        reactionString += "), "
      })


    let lastChar = reactionString.charAt(reactionString.length - 2);
    if(lastChar == ","){
          reactionString = reactionString.substring(0, reactionString.length -2);
    }

    return reactionString;
}

function flattenVoters(voters, pollType, rank, voter_scores){
    let flattened = "";

    if(isRankedType(pollType)){
        flattened = voters.flatMap((voter) => voter.name + " (" + (voter_scores[voter.id] ? voter_scores[voter.id] : "N/A") + ")")
    }
    else {
        flattened = voters.flatMap((voter) => voter.name);
    }
    return flattened.join(", ")
}

function getAncestor(allComments, comment){
    let parent = allComments.find(x => x.id == comment.parent_id);
    if (parent && parent.parent_type == "Comment"){
        return getAncestor(allComments, parent);
    }
    return parent;
}

function getDescendants(allComments, ancestor, descendants){
    let children = allComments.filter(x => x.parent_id == ancestor.id);
    if(children.length > 0){
        children.forEach(c => {
            if(!descendants.find(x => x.id == c.id)){
              descendants.push(c)
            }else {
                return getDescendants(allComments, c, descendants);
            }
        })
    }
    return descendants;
}

//#endregion

//#region Url parsing for keys

export function getKeyFromUrl(isTwikiFormat = true){
    setupVars(isTwikiFormat)

    let pattern = new RegExp("(?<=https?:\/\/" + loomioUrl.host + "\/[A-z]{1}\/)\\b\\w+")
    let documentUrl = new URL(document.URL);
    let pollKey;


    if(documentUrl.pathname.startsWith('/g')){
        let groupKey;

        if(pattern.test(documentUrl)){
            groupKey = pattern.exec(documentUrl)[0];
            if(documentUrl.search.length > 0){
                getPollsFromFilter(documentUrl.search, groupKey)
            }
            else {
                getDiscussionKeysFromGroup();
            }
        }
    }
    else if(documentUrl.pathname.startsWith('/p')){
        if(pattern.test(documentUrl)){
            pollKey = pattern.exec(documentUrl)[0];
            getDiscussionKeyFromPoll(pollKey);
        }
    }
    else if(documentUrl.pathname.startsWith('/d')){
        if(pattern.test(documentUrl)){
            discussionKey = pattern.exec(documentUrl)[0];
            getDiscussion(discussionKey);
        }
    }
    else {
        //we are somewhere we don't want to be, so let the user know
        alert("Loomio poll exporting isn't supported from this page. Supported pages are: the filter page of a group of polls, a thread view page, or an individual poll view page.");
    }
}

function getDiscussionKeyFromPoll(pollKey){
    fetch(apiBase + "polls/" + pollKey, { method: 'GET' })
    .then(response => response.json())
    .then(function(data){
        //so if we have a poll without a thread, we need to take a slightly different path
        if(data.discussions.length > 0){
            discussionKey = data.discussions[0].key;
            let pollId = data.polls[0].id
            getDiscussion(discussionKey, true, pollId);
        }
        else {
            processThreadlessPoll(data);
        }
    });
}

function processThreadlessPoll(data){
    discussion = new Discussion(0, null, "Discussion placeholder", "", [], []);
    //hold poll options for a minute
    if(data.poll_options){
        data.poll_options.forEach(po => {
            pollOptions.push(po);
        })
    }

    let pollCount = 0;
    let isLastThreadlessLoop = false;
    if(data.polls){
        data.polls.forEach(p => {
        let stances = [];
        let results = [];
        pollCount ++;
        p.results.forEach(r => {
            let voters = [];
            let userRank = r.rank;

            r.voter_ids.forEach(v => {
                let currentVoter = data.users.find(user => user.id == v);
                let voter = new User(v, "None");
                if(currentVoter){
                    voter.id = currentVoter.id;
                    voter.name = currentVoter.name;
                }
                else {
                    //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                    checkUserIdAndAddToArray(voter.id);
                }
                voters.push(voter);
            });
            //set this at a high number so that undecideds would always get sorted to the bottom
            let sortPriority = 100;
            if(r.id != 0){
                let option = pollOptions.find(o => o.id == r.id);
                sortPriority = option.priority
            }
            else {
                userRank = "N/A"
            }
            if(r.name.startsWith("poll_")){
                r.name = r.name.split('.').pop();
            }

            let result = new Result(r.id, r.name, r.poll_id, r.score, r.score_percent, r.voter_count, voters, r.voter_percent, sortPriority, userRank, r.voter_scores)
            results.push(result);

            //if the poll has a minimum_options = 0, let's make sure we check if there are "stances" where someone submitted with 0 options selected (which comes back as 'none of the above')
            //if there are 'none of the above' stances, we need to append the user_id to the 'undecided' result for this poll
            if(p.minimum_stance_choices == 0){
                fetch(apiBase + 'stances?from=0&per=200&poll_id=' + p.id, { method: 'GET' })
                .then(response => response.json())
                .then(function(data){
                    let emptyOptions = data.stances.filter(stance => Object.keys(stance.option_scores).length === 0)
                    //need to get the result for this poll where id == 0
                    let noVoteResult = results.find(result => result.id == 0);
                    if(noVoteResult){
                        emptyOptions.forEach(eo => {
                            //check if voter is already in result.voters
                            let abstainingVoter = noVoteResult.voters.find(voter => voter.id == emptyOptions.participant_id)
                            if(!abstainingVoter){
                                let currentNaVoter = data.users.find(user => user.id == emptyOptions.participant_id);
                                let noneAboveVoter = new User(emptyOptions.participant_id, "None");
                                if(currentNaVoter){
                                    noneAboveVoter.id = currentNaVoter.id;
                                    noneAboveVoter.name = currentNaVoter.name;
                                }
                                else {
                                    //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                                    checkUserIdAndAddToArray(noneAboveVoter.id);
                                }
                                noVoteResult.voters.push(noneAboveVoter);
                                noVoteResult.voter_count += 1;
                            }
                        })
                    }
                });
            }
        })
        let poll = new Poll(p.id, p.title, p.details, results, stances, p.decided_voters_count, p.maximum_stance_choices, p.poll_type);
        discussion.polls.push(poll);
        if(pollCount == data.polls.length){
            isLastThreadlessLoop = true;
        }
            getStances(p.id, isLastThreadlessLoop)
        })
    }
}

function getStances(pollId, isLastThreadlessLoop){
    fetch(apiBase + 'stances?from=0&per=400&poll_id=' + pollId, { method: 'GET'})
    .then(response => response.json())
    .then(function(data){
        let stanceComments = [];
        data.stances.forEach((s) => {
            stanceIds.push(s.id);
            let reactions = [];
            let currentParticipant = data.users.find(user => user.id == s.participant_id);
            let participant = new User(s.participant_id, "None");
            if(currentParticipant){
                participant.id = currentParticipant.id;
                participant.name = currentParticipant.name;
            }
            else {
                //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                checkUserIdAndAddToArray(participant.id);
            }

            let stance = new Stance(s.id, s.poll_id, s.reason, participant, reactions, stanceComments.filter(sc => sc.ancestor_id == s.id || sc.parent_id == s.id));
            let discussionPoll = discussion.polls.find(p => p.id == s.poll_id);
            if(discussionPoll)
                discussionPoll.stances.push(stance);
        })
    });
    getReactions(true, isLastThreadlessLoop);
}

function getPollsFromFilter(pollParams, groupKey){
    //first if params includes 'q=' we need to transform to 'query=' because loomio does this under the hood
    const queryParam = /q=/;
    const replacement = "query=";
    if(queryParam.test(pollParams)){
        pollParams = pollParams.replace(queryParam, replacement);
    }

    fetch(apiBase + "polls" + pollParams + "&from=0&exclude_types=group&group_key=" + groupKey + "&per=50")
    .then(response => response.json())
    .then(function(data) {
        if(data.polls && data.polls.length > 0 & data.discussions.length > 0){
            let processSinglePoll = (data.polls.length == 1 ? true : false);
            data.polls.forEach(poll => {
                let discussionPollBelongsTo = data.discussions.find(x => x.id == poll.discussion_id);
                if(discussionPollBelongsTo){
                    getDiscussion(discussionPollBelongsTo.key, processSinglePoll, poll.id)
                }
            })
        }
        else if (data.discussions.length == 0){
            processThreadlessPoll(data)
        }
    })
}

function getDiscussionKeysFromGroup(){
    fetch(apiBase + "discussions/dashboard?exclude_types=group&per=50", {method: 'GET'})
    .then(response => response.json())
    .then(function(data) {
        let keys = [];
        //let openDiscussions = data.discussions.filter(d => d.closed_at == null);
        if(data.discussions){
            data.discussions.forEach((d) => {
                keys.push(d.key);
                return keys;
            })
            keys.forEach((d)=> {
                getDiscussion(d);
            })
        }
    })
}
//#endregion

//#region Fetches from loomio
function getDiscussion(discussionKey, processSinglePoll = false, pollId = null){
    fetch(apiBase + 'discussions/'+ discussionKey + '?exclude_types=poll outcome', {method: 'GET' })
    .then(response => response.json())
    .then(function(data){
        let currentDiscussion = data.discussions.find(discussion => discussion.key == discussionKey);
        discussionId = currentDiscussion.id;
        let currentAuthor = data.users.find(user => user.id == currentDiscussion.author_id);
        let author = new User(currentDiscussion.author_id, "None");
        if(currentAuthor){
            author.id = currentAuthor.id;
            author.name = currentAuthor.name;
        }
        else {
            //if author not found, then add this user id to the unknown array so that we can fetch and find them later
            checkUserIdAndAddToArray(author.id);
        }
        let comments = [];
        let polls = [];
        //instantiate object and fill it with what we have; we can fill the rest later
        discussion = new Discussion(currentDiscussion.id, author, currentDiscussion.title, currentDiscussion.description, comments, polls);
        getNumberOfEvents(discussionId, processSinglePoll, pollId, pageSize)
    });
}

function getNumberOfEvents(discussionId, processSinglePoll, pollId){
    /*
      By default, loomio sets a page size to 30 unless otherwise specified in the 'per' parameter
      In order to make sure we get everything, let's go run a quick fetch to get total events from meta
      Reference in the loomio repo: /app/controllers/api/v1
      def default_page_size
        30
      end
    */
    fetch(apiBase + 'events?discussion_id=' + discussionId + '&per=1&order_by=sequence_id&exclude_types=group discussion', { method: 'GET' })
    .then(response => response.json())
    .then(function(data){
        pageSize = data.meta.total;
        getEventsForDiscussion(discussionId, processSinglePoll, pollId, pageSize);
    })
}

//now we could call the events endpoint to get a big chunk of the data
function getEventsForDiscussion(discussionId, processSinglePoll, pollId, pageSize){
    fetch(apiBase + 'events?discussion_id=' + discussionId + '&per=' + pageSize + '&order_by=sequence_id&exclude_types=group discussion', {method: 'GET' })
    .then(response => response.json())
    .then(function(data){
            //filter out deleted polls
            if(data.polls){
                data.polls = data.polls.filter(poll => poll.discarded_at == null);
            }
            //we'll need to push all comment ids to the global var so we can use it to get reactions below
            let stanceComments = [];
            if(data.comments){
                data.comments.filter(x => x.discarded_at == null).forEach((comm) => {
                    //make sure we filter out comments that belong to discarded polls
                    if(comm.parent_type == "Stance"){
                        let stance = data.stances.find(x => x.id == comm.parent_id)
                        let hasActivePoll = true;
                        if(stance){
                            hasActivePoll = data.polls.find(x => x.id == stance.poll_id)
                        }
                        if(hasActivePoll){
                            commentIds.push(comm.id)
                        }
                    } else {
                        commentIds.push(comm.id)
                    }

                    let reactions = [];
                    let currentAuthor = data.users.find(user => user.id == comm.author_id);
                    let author = new User(comm.author_id, "None");
                    if(currentAuthor){
                        author.id = currentAuthor.id;
                        author.name = currentAuthor.name;
                    }
                    else {
                        //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                        checkUserIdAndAddToArray(author.id);
                    }

                    let comment = new Comment(comm.id, comm.body, author, reactions, comm.parent_id, comm.parent_type, "", null);
                    allComments.push(comment);
                })

                allComments.forEach((c) => {
                    if(c.parent_type == "Comment"){
                        let ancestor = getAncestor(allComments, c);
                        if(ancestor){
                            c.ancestor_id = ancestor.parent_id;
                            c.ancestor_type = ancestor.parent_type;
                            if(ancestor.parent_type == "Discussion"){
                                if(!discussion.general_comments.find(x => x.id == c.id))
                                    discussion.general_comments.push(c)
                            }
                            if(ancestor.parent_type == "Stance"){
                                if(!stanceComments.find(x => x.id == c.id))
                                    stanceComments.push(c)
                            }
                        }
                    }
                    else {
                        if(c.parent_type == "Discussion"){
                            if(!discussion.general_comments.find(x => x.id == c.id))
                                discussion.general_comments.push(c)
                        }
                        if(c.parent_type == "Stance"){
                            if(!stanceComments.find(x => x.id == c.id))
                                stanceComments.push(c)
                        }
                    }
                })
            }

            //let's grab poll options and store them off for
            if(data.poll_options){
                data.poll_options.forEach((po) => {
                    pollOptions.push(po);
                })
            }

            //now let's do polls
            //check if we're only processing a single poll
            if(processSinglePoll){
                let p = data.polls.find(poll => poll.id ==  pollId);
                let stances = [];
                let results = [];
                p.results.forEach(r => {
                    let voters = [];
                    let userRank = r.rank;
                    r.voter_ids.forEach((v) =>{
                            let currentVoter = data.users.find(user => user.id == v);
                            let voter = new User(v, "None");
                            if(currentVoter){
                                voter.id = currentVoter.id;
                                voter.name = currentVoter.name;
                            }
                            else {
                                //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                                checkUserIdAndAddToArray(voter.id);
                            }

                            voters.push(voter);
                        })
                        //set this at a high number so that undecideds would always get sorted to the bottom
                        let sortPriority = 100;
                        if(r.id != 0){
                            let option = pollOptions.find(o => o.id == r.id);
                            sortPriority = option.priority
                        }
                        else {
                            userRank = "N/A"
                        }
                        if(r.name.startsWith("poll_")){
                            r.name = r.name.split('.').pop();
                        }

                        let result = new Result(r.id, r.name, r.poll_id, r.score, r.score_percent, r.voter_count, voters, r.voter_percent, sortPriority, userRank, r.voter_scores)
                        results.push(result);
                })
                //if the poll has a minimum_options = 0, let's make sure we check if there are "stances" where someone submitted with 0 options selected (which comes back as 'none of the above')
                //if there are 'none of the above' stances, we need to append the user_id to the 'undecided' result for this poll
                if(p.minimum_stance_choices == 0){
                    fetch(apiBase + 'stances?from=0&per=200&poll_id=' + p.id, { method: 'GET' })
                    .then(response => response.json())
                    .then(function(data){
                        let emptyOptions = data.stances.filter(stance => Object.keys(stance.option_scores).length === 0)
                        //need to get the result for this poll where id == 0
                        let noVoteResult = results.find(result => result.id == 0);
                        if(noVoteResult){
                            emptyOptions.forEach((eo)=> {
                                //check if voter is already in result.voters
                                let abstainingVoter = noVoteResult.voters.find(voter => voter.id == emptyOptions.participant_id)
                                if(!abstainingVoter){
                                    let currentNaVoter = data.users.find(user => user.id == emptyOptions.participant_id);
                                    let noneAboveVoter = new User(emptyOptions.participant_id, "None");
                                    if(currentNaVoter){
                                        noneAboveVoter.id = currentNaVoter.id;
                                        noneAboveVoter.name = currentNaVoter.name;
                                    }
                                    else {
                                        //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                                        checkUserIdAndAddToArray(noneAboveVoter.id);
                                    }
                                    noVoteResult.voters.push(noneAboveVoter);
                                    noVoteResult.voter_count += 1;
                                }
                            })
                        }
                    });
                }
                let poll = new Poll(p.id, p.title, p.details, results, stances, p.decided_voters_count, p.maximum_stance_choices, p.poll_type);
                discussion.polls.push(poll);
            }
            else {
                if(data.polls){
                    data.polls.forEach((p) => {
                    let stances = [];
                    let results = [];
                    p.results.forEach((r) => {
                        let voters = [];
                        let userRank = r.rank;
                        r.voter_ids.forEach((v) =>{
                                let currentVoter = data.users.find(user => user.id == v);
                                let voter = new User(v, "None");
                                if(currentVoter){
                                    voter.id = currentVoter.id;
                                    voter.name = currentVoter.name;
                                }
                                else {
                                    //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                                    checkUserIdAndAddToArray(voter.id);
                                }

                                voters.push(voter);
                        })
                        //set this at a high number so that undecideds would always get sorted to the bottom
                        let sortPriority = 100;
                        if(r.id != 0){
                            let option = pollOptions.find(o => o.id == r.id);
                            sortPriority = option.priority
                        }
                        else {
                            userRank = "N/A"
                        }
                        if(r.name.startsWith("poll_")){
                            r.name = r.name.split('.').pop();
                        }

                        let result = new Result(r.id, r.name, r.poll_id, r.score, r.score_percent, r.voter_count, voters, r.voter_percent, sortPriority, userRank, r.voter_scores)
                        results.push(result);
                    })
                    if(p.minimum_stance_choices == 0){
                        fetch(apiBase + 'stances?from=0&per=200&poll_id=' + p.id, { method: 'GET'})
                        .then(response => response.json())
                        .then(function(data){
                            let emptyOptions = data.stances.filter(stance => Object.keys(stance.option_scores).length === 0 && stance.poll_id == p.id)
                            //need to get the result for this poll where id == 0
                            let noVoteResult = results.find(result => result.id == 0);
                            if(noVoteResult){
                                //yet another loop
                                emptyOptions.forEach((eo) => {
                                //check if voter is already in result.voters
                                    let abstainingVoter = noVoteResult.voters.find(voter => voter.id == eo.participant_id)
                                    if(!abstainingVoter){
                                        let currentNaVoter = data.users.find(user => user.id == eo.participant_id);
                                        let noneAboveVoter = new User(eo.participant_id, "None");
                                        if(currentNaVoter){
                                            noneAboveVoter.id = currentNaVoter.id;
                                            noneAboveVoter.name = currentNaVoter.name;
                                        }
                                        else {
                                            //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                                            checkUserIdAndAddToArray(noneAboveVoter.id);
                                        }
                                        noVoteResult.voters.push(noneAboveVoter);
                                        noVoteResult.voter_count += 1
                                    }
                                })
                            }
                        });
                    }
                    let poll = new Poll(p.id, p.title, p.details, results, stances, p.decided_voters_count, p.maximum_stance_choices, p.poll_type);
                    discussion.polls.push(poll);
                    })
                }
            }

            //now stances
            //this only includes stances that have a non-empty options, I think
            if(data.stances){
                data.stances.forEach((s) => {
                    //make sure we filter out stances that belong to discarded polls
                    let hasActivepoll = data.polls.find(x => x.id == s.poll_id)
                    if(hasActivepoll){
                        stanceIds.push(s.id);
                        let reactions = [];
                        let currentParticipant = data.users.find(user => user.id == s.participant_id);
                        let participant = new User(s.participant_id, "None");
                        if(currentParticipant){
                            participant.id = currentParticipant.id;
                            participant.name = currentParticipant.name;
                        }
                        else {
                            //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                            checkUserIdAndAddToArray(participant.id);
                        }

                        let stance = new Stance(s.id, s.poll_id, s.reason, participant, reactions, stanceComments.filter(sc => sc.ancestor_id == s.id || sc.parent_id == s.id));
                        let discussionPoll = discussion.polls.find(p => p.id == s.poll_id);
                        if(discussionPoll)
                            discussionPoll.stances.push(stance);
                    }
                })
            }

            //now go fetch reactions and attach them to correct comments/stances
            getReactions(processSinglePoll)
    })
}

//now let's get reactions for things and push them into the object
function getReactions(processSinglePoll, isLastThreadlessLoop = true){
    //TODO: there are also reactions allowed at discussion level and these may need to be captured
    let commentIdsQuery = joinParams(commentIds);
    let stanceIdsQuery = joinParams(stanceIds);
    let commentIdsParam = "";
    let stanceIdsParam = "";
    if(commentIdsQuery){
        commentIdsParam = "comment_ids=" + commentIdsQuery;
    }
    if(stanceIdsQuery){
        if(commentIdsQuery){
           stanceIdsParam = "&stance_ids=" + stanceIdsQuery;
        }
        else {
            stanceIdsParam = "stance_ids=" + stanceIdsQuery;
        }
    }

    fetch(apiBase + 'reactions?' + commentIdsParam + stanceIdsParam, {method: 'GET'})
    .then(response => response.json())
    .then(function(data){
            //first, see if we have any reactions
            if(data.reactions){
                //let's split out by type - i.e., 'Stance' or 'Comment'
                let reactionsStances = data.reactions.filter(reaction => reaction.reactable_type == "Stance");
                let reactionsComments = data.reactions.filter(reaction => reaction.reactable_type == "Comment");

                //now we need to push reactions to the right place
                if(reactionsStances){
                    reactionsStances.forEach((rs) => {
                    let currentAuthor = data.users.find(user => user.id == rs.user_id);
                    let author = new User(rs.user_id,"None");
                    if(currentAuthor){
                        author.id = currentAuthor.id;
                        author.name = currentAuthor.name;
                    }
                    else {
                        //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                        checkUserIdAndAddToArray(author.id);
                    }

                    let reaction = new Reaction(rs.id, rs.reaction, author);

                    //need to loop through polls then through stances to match reactable_id to stance id
                    discussion.polls.forEach((poll) => {
                        let pollStanceWithReactions = poll.stances.find(x => x.id == rs.reactable_id);
                        if(pollStanceWithReactions){
                            pollStanceWithReactions.reactions.push(reaction);
                        }
                    });
                });
                }

                if(reactionsComments){
                    reactionsComments.forEach((rc) => {
                        let currentAuthor = data.users.find(user => user.id == rc.user_id);
                        let author = new User(rc.user_id, "None");
                        if(currentAuthor){
                            author.id = currentAuthor.id;
                            author.name = currentAuthor.name;
                        }
                        else {
                            //if author not found, then add this user id to the unknown array so that we can fetch and find them later
                            checkUserIdAndAddToArray(author.id);
                        }
                        let reaction = new Reaction(rc.id, rc.reaction, author);
                        //is this reaction to a reply or a general comment?
                        if(discussion.general_comments.find(x => x.id == rc.reactable_id)){
                            let genComm = discussion.general_comments.find(x => x.id == rc.reactable_id);
                            genComm.reactions.push(reaction)
                        }
                        else {
                            discussion.polls.forEach(poll => {
                                //now get reactions for comments on stances
                                poll.stances.forEach((stance) => {
                                    if(stance.comments){
                                        let commentsWithReactions = stance.comments.find(y => y.id == rc.reactable_id);
                                        if(commentsWithReactions){
                                            commentsWithReactions.reactions.push(reaction)
                                        }
                                    }
                                })
                            })
                        }
                    })
                }
            }
            //now go fill in users that we couldn't get earlier
            getUsers(processSinglePoll, isLastThreadlessLoop);
    });
}

function getUsers(processSinglePoll, isLastThreadlessLoop = true){
    let userIdQuery = joinParams(unknownUserIds);
    fetch(apiBase + 'profile?xids=' + userIdQuery, { method: 'GET'})
    .then(response => response.json())
    .then(function(data){
        users = data.users;
        //now we need to go through our object and look for any authors/participants/users that have a name = "None"
        //I do not like this inception-like code and think there's a better way for doing deep object parsing/reflection, but this is a start
        discussion.polls.forEach((poll) => {
            poll.results.forEach((result) => {
                result.voters.forEach((voter) => {
                    if(voter.name === 'None'){
                        let actualUser = data.users.find(user => user.id == voter.id);
                        if(actualUser){
                            voter.name = actualUser.name;
                        }
                    }
                })
            })
        });

        convertPollsToMarkdown(isTwiki, isLastThreadlessLoop)
        if(!processSinglePoll){
            parseOuterLevelInfo(isTwiki);
        }
        appendMarkdownToBody(markdown, isLastThreadlessLoop);
    });
}
//#endregion
//#region Markdown parsing

function getHeadingLevel4(isTwiki){
    if(isTwiki){
        return "---++++ ";
    }
    return "#### "
}

function getHeadingLevel5(isTwiki){
    if(isTwiki){
        return "---+++++ ";
    }
    return "##### ";
}

function getBoldFormatting(isTwiki){
    if(isTwiki){
        return "*"
    }
    return "**"
}

function formatCommentString(isTwiki, comment){
    let flattenedReactions = [];
    let reactionString = "";
    if(comment.reactions){
        flattenedReactions = flattenAndCountReactions(comment.reactions);
        if(flattenedReactions){
            reactionString += " (Reactions: " + flattenedReactions + ") ";
        }
    }
    let strippedComment = strip(comment.content);
    return getBoldFormatting(isTwiki) + comment.author.name + ":" + getBoldFormatting(isTwiki) + " " + strippedComment + reactionString + "\n\n";
}

function formatReplyToCommentString(isTwiki, reply, replyDepth, commentAuthor){
    let replyReactions = [];
    let reactionString = "";
    if(reply.reactions){
        replyReactions = flattenAndCountReactions(reply.reactions);
        if(replyReactions){
           reactionString += " (Reactions: " + replyReactions + ") ";
        }
    }
    let replyString = (commentAuthor ? " (in reply to " + commentAuthor + ") " : " ")
    let strippedReply = strip(reply.content);
    let spacing = "&nbsp;&nbsp;&nbsp;&nbsp; ";
    //If there is a need to troubleshoot replies, the following 2 lines will show comment and parent comment ids
    // return spacing.repeat(replyDepth) + getBoldFormatting(isTwiki) + reply.author.name + " (" + reply.id + "):" + getBoldFormatting(isTwiki)
    // + " (in reply to " + commentAuthor + " (" + reply.parent_id + ")) " + strippedReply + reactionString + "\n\n";
    return spacing.repeat(replyDepth) + getBoldFormatting(isTwiki) + reply.author.name + getBoldFormatting(isTwiki)
    + replyString + strippedReply + reactionString + "\n\n";
}

function parseOuterLevelInfo(isTwiki){
    if(discussion.general_comments && discussion.general_comments.length >= 1){
        markdown += getHeadingLevel4(isTwiki) + "General Thread Comments " + "\n\n";

        discussion.general_comments.forEach((comment) => {
            //root level comments
            if(comment.ancestor_id == null){

                markdown += formatCommentString(isTwiki, comment);

                let descendants = []
                getDescendants(discussion.general_comments, comment, descendants);

                if(descendants.length > 0){
                    descendants.forEach(d => {
                        let descendantDepth = 1;
                        if(d.parent_id != comment.id){
                            //only worry about nesting a couple layers because content will become unreadable after that
                            descendantDepth ++
                            if(d.parent_id == comment.parent_id){
                                descendantDepth ++
                            }
                        }
                        markdown += formatReplyToCommentString(isTwiki, d, descendantDepth, comment.author.name);
                    })
                }
            }
        });
        markdown += "\n\n"
    }
}

function convertPollsToMarkdown(isTwiki, isLastThreadlessLoop = true){
    if(isLastThreadlessLoop){
        //add discussion title and any comments that happened at root level
        if(discussion.id != 0){
            markdown += getHeadingLevel4(isTwiki) + "Thread: " + discussion.title + "\n\n";
        }
        markdown += getHeadingLevel4(isTwiki) + "Polls \n\n";

        discussion.polls.forEach((poll) => {
            markdown += getHeadingLevel4(isTwiki) + poll.title + '\n\n';
            if(isTwiki){
                markdown += "|* Option *|* Votes *|* Count/Score *|* Percentage *|\n";
            }
            else {
                markdown += "| Option | Votes | Count/Score | Percentage |\n";
                markdown += "| ------ | ----- | ----- | ---------- |\n";
            }

            let pollResults;

            //FYI: defaulting to order in which options appeared in poll, per Xavi
            //if we want other sort options, a possibility would be to include another param
            pollResults = poll.results.sort((x,y) => x.sort_priority - y.sort_priority);

            pollResults.forEach((result)=> {
                let flattenedVoters = flattenVoters(result.voters, poll.poll_type, result.rank, result.voter_scores);
                let countOrScore;

                if(isRankedType(poll.poll_type)){
                    countOrScore = result.score;
                }
                else {
                    countOrScore = result.voter_count;
                }

                //make sure we put undecideds in a 'No Vote' row
                if(result.id != 0){
                    markdown += "| "
                    + result.name + " | "
                    + flattenedVoters + " | "
                    + countOrScore + " | "
                    + result.score_percent.toFixed(2) + "%"
                    + " |\n";
                }
                else {
                    markdown += "| "
                    + "No Vote | "
                    + flattenedVoters + " | "
                    + countOrScore + " | "
                    + " "
                    + " |\n";
                }
            });

            markdown += "\n\n";
            if(poll.stances){
                markdown  += getHeadingLevel5(isTwiki) + "Comments on poll \n\n";

                //now, let's do the stances
                poll.stances.forEach((stance)=> {
                    let flattenedReactions = [];
                    let completeReactionString = "";
                    if(stance.reactions){
                        flattenedReactions = flattenAndCountReactions(stance.reactions);
                        if(flattenedReactions){
                            completeReactionString += " (Reactions: " + flattenedReactions + ") ";
                        }
                    }

                    let strippedReason = strip(stance.reason);

                    //don't worry about stances with empty reasons as these are 'None of the above's that got rolled up elsewhere
                    if(stance.reason && strippedReason?.trim()){
                        markdown += getBoldFormatting(isTwiki) +  stance.participant.name + ":" + getBoldFormatting(isTwiki) + " " + strippedReason + completeReactionString + "\n\n";
                       
                        if(stance.comments){
                            let processedComments = new Set()
                            stance.comments.forEach((comment) => {
                                // Skip if we've already processed this comment
                                if(processedComments.has(comment.id)) return

                                if(comment.parent_type === "Stance" || comment.parent_type == "Comment"){
                                    processedComments.add(comment.id);
                                    markdown += formatReplyToCommentString(isTwiki, comment, 1, stance.participant.name);

                                    let descendants = [];
                                    getDescendants(stance.comments, comment, descendants);

                                    if(descendants.length > 0){
                                        descendants.forEach(d => {
                                            processedComments.add(d.id);
                                            let descendantDepth = 2;
                                            if(d.parent_id !== comment.id) {
                                                descendantDepth++
                                                if(d.parent_id == comment.parent_id){
                                                    descendantDepth ++
                                                }
                                            }
                                            let descendantParent = stance.comments.find(x => x.id == d.parent_id);
                                            let descAuthor = descendantParent ? descendantParent.author.name : comment.author.name;
                                            markdown += formatReplyToCommentString(isTwiki, d, descendantDepth, descAuthor);
                                        });
                                    }
                                }
                            })
                        }
                    }
                });
                markdown += "\n\n";
            }
        });
    }
}

function appendMarkdownToBody(markdown, isLastThreadlessLoop = true){
    if(isLastThreadlessLoop){
        writeToFile(markdown, fileName[0] + ".md")
    }
}
//#endregion


const writeToFile = (text, fileName) => {
    let textFile = null;
    const makeTextFile = (text) => {
      const data = new Blob([text], {
        type: 'text/plain',
      });
      if (textFile !== null) {
        window.URL.revokeObjectURL(textFile);
      }
      textFile = window.URL.createObjectURL(data);
      return textFile;
    };
    const link = document.createElement('a');
    if(!fileName || fileName.length == 0){
      fileName = "loomio-data-export"
    }
    link.setAttribute('download', fileName);
    link.href = makeTextFile(text);
    link.click();
    //now clear out the global holding the markdown so we don't get extra data
    markdown = ""
  };


